import React, {useEffect, useState} from 'react';
import {Button, Icon, Segment} from "semantic-ui-react";
import './MusicPlayer.css'
const uaParser = require('ua-parser-js');

const ogv = require('ogv');

ogv.OGVLoader.base = process.env.PUBLIC_URL + '/ogv';
const player = new ogv.OGVPlayer();

const convertStringTime = (time:number) => {
    const min = `${Math.floor(time / 60)}`.padStart(2, '0');
    const sec = `${Math.ceil(time % 60)}`.padStart(2, '0');
    return `${min}:${sec}`;
};

const MusicPlayer = ({src}:{src:string}) => {
    const [currentTime,setCurrentTime] = useState<number>(0);
    const [totalTime, setTotalTime] = useState<number>(1);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    // t160v16cdefgab

    player.onended = (event:Event) => {
        // console.log('onEnded');
        setCurrentTime(totalTime);
        setIsPlaying(false);
    };
    player.onseeking= () => {
        // console.log('onseeking');
    };
    player.onplaying = () => {
        // console.log('onplaying');
        setIsPlaying(true);
    };
    player.onpause = () =>{
        setIsPlaying(false);
    };
    player.ontimeupdate = (event:Event) => {
        setCurrentTime(player.currentTime);
        setTotalTime(player.duration);
    };
    player.onloadedmetadata = () => {
        console.log(player.duration);
        setTotalTime(player.duration);
    };

    useEffect(() => {
        if (src !== "") {
            player.pause();
            player.src = src;
            player.load();

            const ua = uaParser(window.navigator.userAgent);
            const isSafari = ua.browser.name === "Safari";
            const isiOS = ua.os.name === "iOS";

            if (!isSafari && !isiOS)
                player.play();
        }
    }, [src]);

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        player.currentTime = parseInt(event.currentTarget.value);
    };

    const toggleMute = () => {
        player.muted = !isMuted;
        setIsMuted(!isMuted);
    };

    const togglePauseAndPlay = () => {
        if (isPlaying) {
            player.pause();
        }else {
            player.play();
        }
    };

    const playTime = convertStringTime(currentTime) + ' / ' + convertStringTime(totalTime);
    // console.log(`current time: ${currentTime} / total time: ${totalTime}`);
    if (src.length === 0)
        return (<></>);

    return (<Segment className={'music-player'}>
        <input className={'e-range'} min={0} max={Math.ceil(totalTime)} name={'music-player-duration'}
               step={1} type={'range'} value={Math.ceil(currentTime)} onChange={handleChange}/>
        <Button icon onClick={()=>{togglePauseAndPlay();}}>
            <Icon name={isPlaying ? 'pause': 'play'}/>
        </Button>
        {playTime}
        <Button icon onClick={()=>{toggleMute();}}>
            <Icon name={isMuted ? 'volume off': 'volume up'}/>
        </Button>
    </Segment>);
};


export default MusicPlayer;
