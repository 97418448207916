export const InstrumentList:{[key:string]:number} = {
    'Lute': 0,
    'Ukulele': 1,
    'Mandolin': 2,
    'Whistle 2': 3,
    'Roncadora': 4,
    'Flute': 5,
    'Chalameu': 6,
    'Tuba': 18,
    'Lyre': 19,
    'Electric Guitar': 20,
    'Piano': 21,
    'Violin': 22,
    'Cello': 23,
    'Harp': 24,
    'Tuned Violin': 25,
    'Tuned Cello': 26,
    'Festival Lute': 50,
    'Festival Ukulele': 51,
    'Festival Mandolin': 52,
    'Festival Whistle': 53,
    'Festival Flute': 54,
    'Tuned Flute': 55,
    'Tuned Whistle': 56,
    'Bass Drum': 66,
    'Snare Drum': 67,
    'Cymbals': 68,
    'Hand Chime': 77,
};


export const InstrumentOptions = Object.keys(InstrumentList).map((key, _) => {
    return {key:key, value:`${InstrumentList[key]}`, text:key}
});
