import React, {useEffect, useState} from 'react';
import {Form, Segment, Select, TextArea} from "semantic-ui-react";
import {InstrumentOptions} from "../InstrumentList";

const MMLForm = ({onChange, defaultMML}:{onChange:({mml,i}:{mml:string, i:number})=>void, defaultMML:{melody:string, chord1:string, chord2:string, inst:number}}) => {
    const REGEX_MML_CODE_FILTER_PATTERN = /[^0-9a-glnortvA-GLNORTV#<>.&+-]/gi;
    const [inst, setInst] = useState<number>(defaultMML.inst);
    const [melody, setMelody] = useState<string>("");
    const [chord1, setChord1] = useState<string>("");
    const [chord2, setChord2] = useState<string>("");
    const codeChangeEffect = () => {
        onChange({
            mml: `MML@${melody},${chord1},${chord2};`,
            i: inst
        });
    };
    useEffect(codeChangeEffect, [inst, melody, chord1, chord2]);
    return (
        <Segment className={'mml-form'}>
        <Form.Field>
            <Select placeholder='악기를 골라주세요.' options={InstrumentOptions} onChange={(e, data) => {
                setInst(parseInt(data.value as string))
            }} defaultValue={`${inst}`} />
        </Form.Field>
        <Form.Field>
            <TextArea rows={5} name={"Code1"} value={melody} onChange={(e) => {
                setMelody(e.currentTarget.value.replace(REGEX_MML_CODE_FILTER_PATTERN,''));
            }} placeholder={defaultMML.melody} maxLength={1200}/>
        </Form.Field>
        <Form.Field>
            <TextArea rows={5} name={"Code2"} value={chord1} onChange={(e) => {
                setChord1(e.currentTarget.value.replace(REGEX_MML_CODE_FILTER_PATTERN,''));
            }} placeholder={defaultMML.chord1} maxLength={800}/>
        </Form.Field>
        <Form.Field>
            <TextArea rows={5} name={"Code3"} value={chord2} onChange={(e) => {
                setChord2(e.currentTarget.value.replace(REGEX_MML_CODE_FILTER_PATTERN,''));
            }} placeholder={defaultMML.chord2} maxLength={500} />
        </Form.Field>
        </Segment>
    );
};


export default MMLForm;
