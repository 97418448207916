import React, {useState} from 'react';
import ReactGA from 'react-ga';
import {Button, Container, DropdownItemProps, Form, Icon, List, Message, Select} from "semantic-ui-react";
import axios from 'axios';
import MMLForm from "./components/MMLForm";
import MusicPlayer from "./components/MusicPlayer";

const defaultMML: { inst: number, melody: string, chord1: string, chord2: string }[] = [{
    inst: 2,
    melody: "t152r8v15l16ef+g+abn61b4r4.g+ab>c+ded4r4.ef+g+abn73b4r4.g+ab>c+ded4<f+g+ab>c+8rc+<arerf+rar>d8c+8<brarg+rbra8v11<c-rc+4v15>>c+8rc+<arerf+rar>d8c+8<brarg+r>f+re8v11<<dre4v15>f+rg+ag+rf+rerarc+rerdref+e8d8c+rdre8r8f+rg+ag+rf+rerarc+rerf+rarg+rbra4>g+f+edc+8rc+<arerf+rar>d8c+8<brarg+rbra8v11<c-rc+4v15>>c+8rc+<arerf+rar>d8c+8<brarg+r>f+re8v11<<dre4v15>f+rg+ag+rf+rerarc+rerdref+e8d8c+rdre8r8f+rg+ag+rf+rerarc+rerf+rarg+rbra4>f+gef+<<a8>f+rdr<arg4r8ef+g8>erc+r<arf+4r8f+ga8>f+rdr<arb8>grf+rerdrerf+8gf+e4r8<f+ga8>f+rdr<arg4r8ef+g8>erc+r<arf+4r8f+ga8>f+rdr<arb8>grf+rerl8arl20c+dc+c-c+19l4drf+8l16f+gabag+f+aead+acab>gegc-g<g>g<e>g<ef+gf+edn49c+<a>ec+aen73a>ec+<aec+<ae>d>f+df+<an78f+n78d>f+<<abagf+ed+b+f+n63a>f+d+af+>d+c<af+d+c<ag>ec-ege<e>ec-ec-ge<bgf+b>fc+dfrgg+b>fc+dfdc+<ba+n73a+f+c+<a+f+c+>b>d<bg+fd<bg+>f+rfrf+rfrf+4r4>c+8rc+<a8e8f+8ar>d4c+8erdrc+rc-2c+8rc+<a8e8f+8ar>drf+rerc+c+dr<brl8arl32rc+def+g+abl8n73rl16arerf+rar>d4c+rerdrc+rc+8r<bb4>c+8r8<arerf+rar>d8f+8ererd+rd+rd8ef+erdrc+8r8<arerf+rar>d4c+rerdrc+rc+8r<bb4>c+8r8<arerf+rarg+rbrv10l2.<c+c-4c+d4c+e4d2e4e4v13ee4ab4l2>c+edl4eel16<ean61aean61eeg+bg+bg+beean61aean61eeg+bg+bg+beean61ebg+bg+ean61ebg+bg+v15>aef+g+aef+g+aef+g+aef+g+l4arer<a1",
    chord1: "r1r8v13l16ef+g+abn61b4r4.b>def+g+ag+4r4.ef+g+abn73b4r4a8raerc+rdrf+ra8a8g+rf+rerg+re4r4a8raerc+rdrf+ra8a8g+rf+rer>drc+4r4<dref+erdrc+rer<arn61rbr>c+dc+8<b8arbr>c+8r8dref+erdrc+rer<ar>c+rdrf+rerg+re4r4a8raerc+rdrf+ra8a8g+rf+rerg+re4r4a8raerc+rdrf+ra8a8g+rf+rer>drc+4r4<dref+erdrc+rer<arn61rbr>c+dc+8<b8arbr>c+8r8dref+erdrc+rer<ar>c+rdrf+rerg+rl4ero2d<a>e<ba>el8d<a>df+l4df+ec-ec-a8a8l32agf+edc+<bal4>d<a>e<ba>el8d<a>df+l4df+e<ba>c+dl32>dc+<bagf+edo5d8l16def+g+f+ed8c+8<b8a8>g>ec-e<g>e<e>e<c->el2r.r8<g4f+8e8dr<f+a4>c8e8g4rc-8c+8dfl16f+a+f+c+<a+f+c+<a+>>fbfdd<bfd>drc+rdrc+rd4r1r1r1r1r4<a2>d2arn73rbrara8rg+g+4a8r8erc+rdrf+ra8>d8c+rc+rcrcrc-8c+dc+r<bra8r8erc+rdrf+ra4arn73rbrara8rg+g+4a8r8erc+rdrf+rerg+rl4ar2v12<de2.f+e2.gf+2g+bv15a2.b>c+2.de2g2f+2g+bn73rbbn73rbbl8>c+c+<bb>c+c+<bbv13l4<aaaa>er<bre1",
    chord2: "v13l1o2eg+b>e2.l32edc+<bag+f+el8<arar>d2ererl16arbr>c+4l8<<arar>d2ererl16arbr>c+4l8<drdrc+rc+rc-rer<a2>drdrc+rc+rc-rer<a4l32>ag+f+edc+<bal8arar>d2ererl16arbr>c+4l8<<arar>d2ererl16arbr>c+4l8<drdrc+rc+rc-rer<a2>drdrc+rc+rc-rerf+4l32f+edc+<bagf+l1.rrrrrl2r>d4r.el8reee<a2raaa>d2rl16ddddddd+2l8rd+d+d+e2reeel2fff+c-l8f+ff+ff+4l32f+edc+<bag+f+a4r2r8l16aaa4r4>e4e8ee<a4r4l8>drdrer<brl4araa>dderee<aa>dded+dl32edc+<bagf+el4aa>dderee<aa>dr<aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaar>er<ar>er<a>e<a>e<aaaaar>er<a1"
}];

const CONVERT_API_URL = 'https://api.yoko.so/v1/convert';
const TWITTER_URL = 'https://twitter.com/rajephon';

ReactGA.initialize('UA-127761829-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const App: React.FC = () => {
    const [channelCount, setChannelCount] = useState<number>(defaultMML.length);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [mmls, setMmls] = useState<{ [ch: string]: { i: number, mml: string } }>({});
    const [musicSrc, setMusicSrc] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const onClickSubmit = async () => {
        setErrorMessage("");
        setIsLoading(true);
        const channels: { i: number, mml: string }[] = [];
        for (const key in mmls) {
            const val = mmls[key];
            if (val.mml !== "MML@,,;")
                channels.push(val);
            if (val.mml.length > 2507) {
                setErrorMessage(`채널당 2500글자 이하로 입력해주세요.. (현재:${val.mml.length-7})`);
                setIsLoading(false);
                return;
            }
        }

        if (channels.length < 1) {
            channels.push({
                i: defaultMML[0].inst,
                mml: `MML@${defaultMML[0].melody},${defaultMML[0].chord1},${defaultMML[0].chord2};`
            })
        }


        const data = {
            channels: channels
        };
        try {
            const res = await axios.post(CONVERT_API_URL, data);
            console.log(res.data.url);
            setIsLoading(false);
            setMusicSrc(res.data.url);

            ReactGA.event({
                category: 'Convert',
                action: 'Success',
                label: res.data.url
            });
        } catch (error) {
            console.error(JSON.stringify(error));
            setIsLoading(false);
            setErrorMessage(error.message);

            ReactGA.event({
                category: 'Convert',
                action: 'Fail',
                label: error.message
            });
        }
    };

    const channelOptions: DropdownItemProps[] = [];
    for (let i = 1; i <= 8; i++) {
        channelOptions.push({key: `${i}`, value: i, text: `${i}인`});
    }

    return (
        <div className="App">
            <Container className={'board'}>
                <h1>요코소 프로젝트 Yokoso Project</h1>
                <p>
                    사이트 오픈 전 컨버터를 선공개합니다. 앞으로 요코소 개발 관련된 근황 및 소식을 <a href={TWITTER_URL}>트위터</a>에 업데이트 할 예정이니 많은 관심 부탁드립니다.
                </p>
                <Form>
                    <Select placeholder='합주인원' options={channelOptions} onChange={(e, data) => {
                        setChannelCount(data.value as number);
                    }} defaultValue={channelCount}/>

                    {[...Array(channelCount)].map((e, idx) => {
                        return <MMLForm key={`ch${idx}`} onChange={({mml, i}) => {
                            const newMmls = {...mmls};
                            newMmls[`${idx}`] = {i: i, mml: mml};
                            setMmls(newMmls);
                        }} defaultMML={(defaultMML.length > idx) ? defaultMML[idx] : {
                            melody: '',
                            chord1: '',
                            chord2: '',
                            inst: 0
                        }}/>
                    })}
                    <Form.Field>
                        {!isLoading ? <Button icon labelPosition='left' type='submit' primary onClick={() => {
                            onClickSubmit();
                        }}><Icon name='music'/>
                            CONVERT
                        </Button> : <Button loading disabled>Loading</Button>}
                    </Form.Field>
                </Form>
                {errorMessage &&
                    <Message negative>
                        <Message.Header>ERROR</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                }
                <MusicPlayer src={musicSrc}/>
            </Container>

            <Container className={'bottom'} style={{marginTop:50}}>
                <h3>기타</h3>
                <List>
                    <List.Item>
                        <h4>샘플 코드 출처</h4>
                        <p><a href="https://skypine.tistory.com/242">[마비노기] 쿠키런 - 민트초코쿠키</a> / <a href="https://twitter.com/quaver820">레라드</a>님</p>
                    </List.Item>
                    <List.Item>
                        <h4>사운드폰트</h4>
                        <p>컨버팅에 사용된 사운드폰트는 마비노기@NEXON의 저작물이며 (주)넥슨코리아에 저작권이 있습니다.</p>
                    </List.Item>
                    <List.Item>
                        <h4>사이트 개발</h4>
                        <p><a href={TWITTER_URL}>rajephon</a></p>
                        <h4>문의사항</h4>
                        <p><a href={TWITTER_URL}>트위터</a> 혹은 <a href="mailto:rajephon@gmail.com">rajephon(at)gmail.com</a></p>
                    </List.Item>
                </List>
                <h3>오픈소스 라이선스</h3>
                <List>
                    <List.Item>
                        <h4>YKSConverter</h4>
                        <a href="https://github.com/rajephon/YKSConverter">https://github.com/rajephon/YKSConverter</a><br/>
                        Copyright 2018 rajephon &lt;rajephon@gmail.com&gt;<br/>
                        BSD 2-Clause "Simplified" License
                    </List.Item>
                    <List.Item>
                        <h4>ogv.js</h4>
                        <a href="https://github.com/brion/ogv.js">https://github.com/brion/ogv.js</a><br/>
                        <p>
                            libogg, libvorbis, libtheora, libopus, nestegg, libvpx, and dav1d are available under their respective licenses, and the JavaScript and C wrapper code in this repo is licensed under MIT.<br/>
                            Based on build scripts from <a href="https://github.com/devongovett/ogg.js">https://github.com/devongovett/ogg.js</a><br/>
                            <a href="https://github.com/brion/audio-feeder">AudioFeeder</a>'s dynamicaudio.as and other Flash-related bits are based on code under BSD license, (c) 2010 Ben Firshman.
                        </p>
                    </List.Item>
                </List>
            </Container>
        </div>
    );
};

export default App;
